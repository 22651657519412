export default {
    install(Vue) {
        Vue.mixin({
            data(){
                return {
                    clickBtuuonRepeat: false,
                    isShowArr: ['pdf.kafanol.com','pdf1.kafanol.com','pdf2.ncdsnc.com','pdf.officeon.cn','pdfzh.ncdsnc.com','pdf.whkafan.com','pdf.ncdsnc.com'],
                    isShowArrData:[
                        {url:'pdf.kafanol.com',lxr:'孙炜',phone:'13262222601',site:'安徽省合肥市蜀山区经济开发区湖光路1299号电商园1幢712',name:'合肥卡饭信息科技有限公司'},
                        {url:'pdf1.kafanol.com',lxr:'孙炜',phone:'13262222601',site:'安徽省合肥市蜀山区经济开发区湖光路1299号电商园1幢712',name:'合肥卡饭信息科技有限公司'},
                        {url:'pdf2.ncdsnc.com',lxr:'陈少文',phone:'18717885820',site:'湖南省衡阳市蒸湘区联合街道杨柳综合市场2号楼201房024号',name:'芜湖界佳网络科技有限公司湖南分公司'},
                        {url:'pdf.officeon.cn',lxr:'王瞾',phone:'13883317349',site:'安徽省合肥市高新区创新大道2800号创新产业园二期J2区C座2010室',name:'合肥云表信息科技有限公司'},
                        {url:'pdf.ncdsnc.com',lxr:'陈少文',phone:'13262222601',site:'福建省莆田市城厢区凤凰山街道文献西路488号502室(集群注册)',name:'芜湖界佳网络科技有限公司莆田分公司'},
                        {url:'pdfzh.ncdsnc.com',lxr:'周俊升',phone:'15555487515',site:'河南自贸试验区郑州片区(郑东)榆林北路36号绿地中心双子塔北塔2011室',name:'芜湖界佳网络科技有限公司郑州第二分公司'},
                        {url:'pdf.whkafan.com',lxr:'周俊升',phone:'13262222601',site:'芜湖市湾沚区安徽新芜经济开发区北航芜湖数字信息产业园5号楼8层B区22室',name:'芜湖卡饭网络科技有限公司'},
                    ],
                    footerCompany: ['pdf.kafanol.com','pdf1.kafanol.com','pdf2.ncdsnc.com','pdf.officeon.cn','pdf.ncdsnc.com','pdfzh.ncdsnc.com','pdf.whkafan.com'],
                    matchHostArr : [
                        { host: 'pdf.jnkn8.top', name: '天桥区柯南网络科技工作室' },
                        { host: 'pdf.zhr01.cn',name: '重庆舟荣网络技术有限公司' },
                        { host: 'pdf.jnhqwl.com',name: '济南历城宏强网络工作室' },
              
              
                        { host: 'pdf.kafanol.com',name: '合肥卡饭信息科技有限公司' },
                        { host: 'pdf1.kafanol.com',name: '合肥卡饭信息科技有限公司' },
                        { host: 'localhost:8080',name: '合肥卡饭信息科技有限公司' },
                        { host: 'pdf2.ncdsnc.com',name: '芜湖界佳网络科技有限公司湖南分公司' },
                        { host: 'pdf.officeon.cn',name: '合肥云表信息科技有限公司' },
                        { host: 'pdf.ncdsnc.com',name: '芜湖界佳网络科技有限公司莆田分公司' },
                        { host: 'pdfzh.ncdsnc.com',name: '芜湖界佳网络科技有限公司郑州第二分公司' },
                        { host: 'pdf.whkafan.com',name: '芜湖卡饭网络科技有限公司' },
                        { host: 'pdf.kfsafe.cn',name: '卡饭（上海）信息安全有限公司',icp: '沪ICP备2020031077号' },
                      ],
                }
            },
            methods:{

                //将url转成对象
                parseQueryString(url) {
                    //url参数转对象
                    url = !url ? window.location.href : url
                    if (url.indexOf('?') === -1) {
                    return null
                    }
                    let search = url[0] === '?' ? url.substr(1) : url.substring(url.lastIndexOf('?') + 1)
                    if (search === '') {
                    return null
                    }
                    search = search.split('&')
                    let query = {}
                    for (let i = 0; i < search.length; i++) {
                    let pair = search[i].split('=')
                    // query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
                    query[pair[0]] = pair[1] || ''
                    }
                    return query
                },

                isToPdf(){
                    let difference = ''
                    let res = this.parseQueryString(window.location.href)
                    if(res && res.bd_vid){
                        difference = 'bd_vid'
                    } else if(res && res.qhclickid){
                        difference = 'qhclickid'
                    }

                    //组织给后台参数
                    let par = {
                        tg_id: sessionStorage.getItem('tg_id') == 'null' ? '0' : sessionStorage.getItem('tg_id'),
                        tg_type: sessionStorage.getItem('tg_type') == 'null' ? 0 : Number(sessionStorage.getItem('tg_type')),
                        cid: sessionStorage.getItem('pdf_id') == 'null' ? 0 : Number(sessionStorage.getItem('pdf_id')), //渠道号
                        tg_url_type: sessionStorage.getItem('tg_url_type') == 'null' ? 0 : Number(sessionStorage.getItem('tg_url_type')),
                        url: window.location.host
                    }

                    window.open(`http://${window.location.host}/convert/conversion/PDF2Word?id=${par.cid}&${difference == 'bd_vid' ? 'bd_vid' : 'qhclickid'}=${par.tg_id}`)
                }
            }
        })
    }
}